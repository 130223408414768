export const environment = {
  // enviroment = tenant
  production: false,

  name: 'dev',

  code: null,
  host: 'dev.telepain.md', // this is only for local debugging

  // environment details would be fetched from here
  source: 'https://dev.openage.in/directory/api/tenants',

  organization: { code: null },
  loginTypes: ['email'],
  cacheDuration: 10, // minutes

  // if services section exists then it would be used
  services: [],
  // services: [{
  //   code: 'directory',
  //   url: 'https://dev.openage.in/directory/api'
  // }, {
  //   code: 'ams',
  //   url: 'https://dev.openage.in/ams/api'
  // }, {
  //   code: 'sendIt',
  //   url: 'https://dev.openage.in/sendIt/api'
  // }, {
  //   code: 'drive',
  //   url: 'https://dev.openage.in/drive/api'
  // }, {
  //   code: 'gateway',
  //   url: 'https://dev.openage.in/gateway/api'
  // }, {
  //   code: 'discover',
  //   url: 'https://dev.openage.in/discover/api'
  // }, {
  //   code: 'insight',
  //   url: 'https://dev.openage.in/insight/api'
  // }],

  // if navs section exists then it would be used
  navs: []
  // navs: [{
  //   title: 'Docs',
  //   code: 'docs',
  //   icon: {
  //     fa: 'info'
  //   },
  //   routerLink: ['/docs'],
  //   items: [{
  //     title: 'Auth',
  //     code: 'docs.auth',
  //     routerLink: ['/docs', 'auth'],
  //   }, {
  //     title: 'Discovery',
  //     code: 'docs.discovery',
  //     routerLink: ['/docs', 'discovery'],
  //   }, {
  //     title: 'Directory',
  //     code: 'docs.directory',
  //     routerLink: ['/docs', 'directory'],
  //   }, {
  //     title: 'Content',
  //     code: 'docs.content',
  //     routerLink: ['/docs', 'content'],
  //   }, {
  //     title: 'Drive',
  //     code: 'docs.drive',
  //     routerLink: ['/docs', 'drive'],
  //   }, {
  //     title: 'Communication',
  //     code: 'docs.send-it',
  //     routerLink: ['/docs', 'send-it'],
  //   }, {
  //     title: 'Insight',
  //     code: 'docs.insight',
  //     routerLink: ['/docs', 'insight'],
  //     items: [{
  //       title: 'Activities',
  //       code: 'docs.insight.journals',
  //       routerLink: ['/docs', 'journal-list'],
  //     }]
  //   }]
  // }],
};
