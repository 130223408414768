import { ErrorHandler, Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { Action, Link, Menu, Nav } from '~/app/open-age/core/structures';
import { Entity } from '../../open-age/core/models/entity.model';

import { ComponentType } from '@angular/cdk/portal';
import { Pic } from '~/app/open-age/core/models';
import { IContextMenuHandler } from '~/app/open-age/core/services/context-menu-handler.interface';
import { IEntityHandler } from '~/app/open-age/core/services/entity-handler.interface';
import { Organization, Role, Tenant } from '~/app/open-age/directory/models';
import { ConfirmDialogComponent } from '~/app/shared/components/confirm-dialog/confirm-dialog.component';
import { environment } from '~/environments/environment';
import { RoleService } from '../../open-age/core/services/role.service';
import { NavService } from './nav.service';
@Injectable({
  providedIn: 'root',
})
export class UxService implements ErrorHandler,
  IContextMenuHandler,
  IEntityHandler {

  private _errors = new Subject<string>();
  private _entitySubject = new Subject<Entity>();
  private _search = new Subject<string>();
  private _navBar = new Subject<Link>();
  private _contextMenu = new Subject<Menu>();
  private _deviceSubject = new Subject<string>();

  private _sideNavShowSubject = new Subject<boolean>();

  private _device = 'desktop';
  private _searchText = '';
  private _entity: Entity;

  private _currentTenant: Tenant;
  private _currentOrganization: Organization;
  private _currentRole: Role;
  private _logo: Pic;

  private _logoSubject = new Subject<Pic>();

  private _sideNavShow = false;

  searchChanges = this._search.asObservable();
  navBarChanges = this._navBar.asObservable();
  contextMenuChanges = this._contextMenu.asObservable();
  errors = this._errors.asObservable();

  entityChanges = this._entitySubject.asObservable();
  deviceChanges = this._deviceSubject.asObservable();

  sideNavShowChanges = this._sideNavShowSubject.asObservable();

  logoChanges = this._logoSubject.asObservable();

  constructor(
    private roleService: RoleService,
    private navService: NavService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this._currentRole = this.roleService.currentRole();
    this._currentTenant = this.roleService.currentTenant();
    this._currentOrganization = this.roleService.currentOrganization();
    this.setLogo();
    this.setNav();

    this.roleService.tenantChanges.subscribe((t) => {
      this._currentTenant = t;
      this.setLogo();
      this.setNav();
    });
    this.roleService.organizationChanges.subscribe((t) => {
      this._currentOrganization = t;
      this.setLogo();
      this.setNav();
    });
    this.roleService.roleChanges.subscribe((r) => {
      this._currentRole = r;
      this.setLogo();
      this.setNav();
    });

    this.navService.navChanges.subscribe((n) => {
      this.resetContextMenu();
    });
  }

  private setNav() {
    let navs = [];
    if (this._currentOrganization && this._currentOrganization.navs && this._currentOrganization.navs.length) {
      navs = this._currentOrganization.navs.map((n) => new Link(n));
    } else if (this._currentTenant && this._currentTenant.navs && this._currentTenant.navs.length) {
      navs = this._currentTenant.navs.map((n) => new Link(n));
    } else {
      navs = environment.navs.map((n) => new Link(n));
    }

    this.navService.setNav(navs);
  }

  private setLogo() {
    if (this._currentTenant && this._currentTenant.logo) {
      this._logo = this._currentTenant.logo;
    } else if (this._currentRole && this._currentRole.organization && this._currentRole.organization.logo) {
      this._logo = this._currentRole.organization.logo;
    }

    this._logoSubject.next(this._logo);
  }

  getItemsLength() {
    let items = 10
    if (window.innerWidth >= 2000) {
      items = 12
    }
    if (window.innerWidth < 2000 && window.innerWidth >= 1700) {
      items = 8
    }
    if (window.innerWidth < 1700 && window.innerWidth >= 1200) {
      items = 7
    }
    if (window.innerWidth < 1200 && window.innerWidth >= 1000) {
      items = 5
    }
    if (window.innerWidth < 1000 && window.innerWidth >= 900) {
      items = 3
    }
    if (window.innerWidth < 900 && window.innerWidth >= 800) {
      items = 2
    }
    if (window.innerWidth < 800 && window.innerWidth >= 500) {
      items = 2
    }
    if (window.innerWidth < 500) {
      items = 1
    }
    return items
  }

  getLogo(): Pic {
    return this._logo;
  }

  // getNavs(): Link[] {
  //   return this.navService.getNavs();
  // }

  handleError(error: any): void {
    const err = error instanceof Error ? error.message : error;
    this._errors.next(err);
    this.snackBar.open(err, null, {
      duration: 3000,
    });
  }

  handleLongError(error: any): void {
    const err = error instanceof Error ? error.message : error;
    this._errors.next(err);
    this.snackBar.open(err, null, {
      duration: 10000,
    });
  }

  showInfo(message: string, title?: string): void {
    this.snackBar.open(message, title || 'Ok', {
      duration: 3000,
    });
  }

  getTextFromEvent($event: any, newPlaceholder: string): string {
    if ($event.type && $event.type === 'keypress') {
      if ($event.key !== 'Enter') {
        return;
      } else {
        $event.preventDefault();
        $event.target.blur();
        return;
      }
    }

    let subject: string = $event.target.tagName === 'INPUT' ? $event.target.value : $event.target.innerText;

    if (!subject) {
      return;
    }
    subject = subject.replace(/\r?\n/g, '').trim();

    if (subject === newPlaceholder) {
      if ($event.target.tagName === 'INPUT') {
        $event.target.value = newPlaceholder;
      } else {
        $event.target.innerText = newPlaceholder;
      }
      return;
    }

    return subject;
  }

  setTextFromEvent($event: any, newPlaceholder: string) {
    if ($event.type && $event.type === 'keypress') {
      return;
    }
    if ($event.target.tagName === 'INPUT') {
      $event.target.value = newPlaceholder;
    } else {
      $event.target.innerText = newPlaceholder;
    }
  }

  setSearchText(search: string) {
    this._searchText = search;
    this._search.next(search);
  }

  getSearchText() {
    return this._searchText;
  }

  resetSearchText() {
    this._searchText = null;
    this._search.next(null);
  }

  setNavBar(nav: Link) {
    this._navBar.next(nav);
  }

  resetNavBar() {
    this._navBar.next(null);
  }

  setContextMenu(obj: Menu | any[]) {
    if (obj instanceof Menu) {
      this._contextMenu.next(obj);
    } else {
      const items: Action[] = [];
      obj.forEach((item) => {
        items.push(new Action(item));
      });
      const menu = new Menu(items);
      this._contextMenu.next(menu);
    }
  }

  resetContextMenu() {
    this._contextMenu.next(null);
  }

  setEntity(entity: Entity | any) {
    if (!this._entity && !entity) {
      return;
    }

    if (this._entity && entity) {
      if (this._entity.id === entity.id && this._entity.type === entity.type) {
        return;
      }
    }

    this._entity = new Entity(entity);
    this._entitySubject.next(entity);
  }

  resetEntity() {
    this._entity = null;
    this._entitySubject.next(null);
  }

  currentEntity() {
    return this._entity;
  }
  reset() {
    this.navService.resetTitle();
    this.navService.resetBreadcrumb();
    this.resetContextMenu();
    this.resetEntity();
  }

  showSideNav(show: boolean) {
    this._sideNavShow = show;
    this._sideNavShowSubject.next(this._sideNavShow);
  }

  getShowSideNav() {
    return this._sideNavShow;
  }

  getDevice() {
    return this._device;
  }

  setDevice(device: 'mobile' | 'desktop') {
    this._device = device;
    this._deviceSubject.next(this._device);
  }

  openDialog<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<any>) {
    config = config || {};
    config.width = '450px';
    return this.dialog.open(componentOrTemplateRef, config);
  }

  onConfirm(options?: {
    title?: string,
    message?: string,
    okTitle?: string,
    cancelTitle?: string
  }) {
    const subject = new Subject<boolean>();
    const dialog = this.dialog.open(ConfirmDialogComponent, { width: '350px' });
    const instance = dialog.componentInstance;

    options = options || {};
    instance.title = options.title || instance.title;
    instance.message = options.message || instance.message;
    instance.okButtonText = options.okTitle || instance.okButtonText;
    instance.cancelButtonText = options.cancelTitle || instance.cancelButtonText;

    dialog.afterClosed().subscribe((r) => {
      if (r) {
        subject.next(true);
      }
    });

    return subject.asObservable();
  }
}
