import { Component, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services/ux.service';
import { ValidationService } from '~/app/core/services/validation.service';
import { RoleService } from '~/app/open-age/core/services';
import { Organization } from '~/app/open-age/directory/models';
import { Organization as WelcomeOrganization } from '~/app/open-age/welcome/models/organization.model';
import { FormBuilder, Form, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { OrganizationService as WelcomeOrganizationService } from '~/app/open-age/welcome/services';
import { DirectoryRoleService, DivisionService } from '~/app/open-age/directory/services';
import { Link } from '~/app/open-age/core/structures';
import { NavService } from '~/app/core/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-practice-new',
  templateUrl: './practice-new.component.html',
  styleUrls: ['./practice-new.component.css']
})
export class PracticeNewComponent implements OnInit {

  practice: Organization;
  welcomePractice: WelcomeOrganization = new WelcomeOrganization({})
  isProcessing: boolean;
  id: string;
  isCurrent = true;
  isSubmitted: boolean = false
  page: Link;
  profileForm: FormGroup | any;
  states = ["Alabama (AL)", "Alaska (AK)", "Arizona (AZ)", "Arkansas (AR)", "California (CA)",
    "Colorado (CO)", "Connecticut (CT)", "Delaware (DE)", "District of Columbia (DC)",
    "Florida (FL)", "Georgia (GA)", "Hawaii (HI)", "Idaho (ID)", "Illinois (IL)",
    "Indiana (IN)", "Iowa (IA)", "Kansas (KS)", "Kentucky (KY)", "Louisiana (LA)",
    "Maine (ME)", "Marshall Islands (MH)", "Maryland (MD)", "Massachusetts (MA)",
    "Michigan (MI)", "Minnesota (MN)", "Mississippi (MS)", "Missouri (MO)", "Montana (MT)",
    "Nebraska (NE)", "Nevada (NV)", "New Hampshire (NH)", "New Jersey (NJ)", "New Mexico (NM)",
    "New York (NY)", "North Carolina (NC)", "North Dakota (ND)", "Ohio (OH)", "Oklahoma (OK)",
    "Oregon (OR)", "Pennsylvania (PA)", "Rhode Island (RI)", "South Carolina (SC)",
    "South Dakota (SD)", "Tennessee (TN)", "Texas (TX)", "Utah (UT)", "Vermont (VT)",
    "Virginia (VA)", "Washington (WA)", "West Virginia (WV)", "Wisconsin (WI)",
    "Wyoming (WY)"]

  constructor(
    private auth: RoleService,
    private formBuilder: FormBuilder,
    private roleService: DirectoryRoleService,
    private errorHandler: UxService,
    private validator: ValidationService,
    private navService: NavService,
    private route: ActivatedRoute,
    private divisionService: DivisionService,
    private welcomeOrganizationService: WelcomeOrganizationService
  ) {
    this.practice = new Organization({})
    this.createForm();
  }

  ngOnInit() {
    this.page = this.navService.register(`/practice/list/new`, this.route, (isCurrent, params) => {
      this.isCurrent = isCurrent;
      if (this.isCurrent) {
        this.setContext();
      }
    });
    this.practice = new Organization({})
  }

  add() {
    if (this.profileForm.invalid) {
      return
    }
    let code = "practice" + new Date().getTime()
    this.practice.name = this.profileForm.value.name
    this.practice.email = this.profileForm.value.email
    this.practice.phone = this.profileForm.value.phone
    this.practice.address.location = this.profileForm.value.location
    this.practice.address.line1 = this.profileForm.value.line1
    this.practice.address.city = this.profileForm.value.city
    this.practice.address.state = this.profileForm.value.state
    this.practice.address.line2 = this.profileForm.value.line2
    this.practice.address.pinCode = this.profileForm.value.pinCode
    this.practice.meta.ein = this.profileForm.value.ein
    this.practice.meta.fax = this.profileForm.value.fax
    this.practice.timezoneOffset = this.profileForm.value.timezoneOffset

    if (!this.practice.name) {
      // this.errorHandler.handleError("Name is required")
      return
    }
    if (!this.practice.address.location) {
      // this.errorHandler.handleError("Practice location is required")
      return
    }
    if (!this.practice.address.city) {
      // this.errorHandler.handleError("City is required")
      return
    }
    if (!this.practice.address.state) {
      // this.errorHandler.handleError("State is required")
      return
    }
    if (!this.practice.phone) {
      // this.errorHandler.handleError("Phone is required")
      return
    } else {
      // this.practice.phone = this.practice.phone.replace(/-/g, "")
    }
    if (!this.practice.email) {
      // this.errorHandler.handleError("Email is required")
      return
    }
    // if (this.practice.email) {
    //   if (this.validator.isEmailValid(this.practice.email)) {
    //     this.errorHandler.handleError("Invalid Email")
    //     return
    //   }
    // }
    // if (this.practice.phone) {
    //   if (this.validator.isMobileValid(this.practice.phone)) {
    //     this.errorHandler.handleError("Invalid Phone")
    //     return
    //   }
    // }
    // if (this.practice.phone && this.practice.phone.length != 10) {
    //   this.errorHandler.handleError("Invalid Phone")
    //   return
    // }
    if (this.practice.meta.fax) {
      this.practice.meta.fax = this.practice.meta.fax.replace(/-/g, "")
    }
    this.isProcessing = true

    this.roleService.create({
      organization: {
        name: this.practice.name,
        code: code,
        type: "practice",
        email: this.practice.email,
        phone: this.practice.phone,
        address: this.practice.address,
        meta: this.practice.meta,
        timezoneOffset: this.practice.timezoneOffset,
        logo: {
          "url": "https://openage.github.io/themes/oppos/v1/icons/placeholder/hospital-placeholder.svg"
        }
      },
      employee: {
        profile: this.auth.currentRole().profile,
        code: code
      },
      code: code
    }).subscribe((role) => {
      let meta = this.practice.meta || {}
      meta.email = this.practice.email
      meta.phone = this.practice.phone
      this.divisionService.create({ code: this.practice.address.location, name: this.practice.address.location, meta: meta, address: this.practice.address, organization: role.organization }).subscribe(() => {
        this.errorHandler.showInfo("Practice Added")
        this.isProcessing = false
        this.navService.goto("/practice/list")
      }, err => {
        if (this.practice.meta.fax) {
          this.practice.meta.fax = this.numberFormat(this.practice.meta.fax)
        }
        this.practice.phone = this.numberFormat(this.practice.phone)
      })
    }, err => {
      if (this.practice.meta.fax) {
        this.practice.meta.fax = this.numberFormat(this.practice.meta.fax)
      }
      this.practice.phone = this.numberFormat(this.practice.phone)
    })
  }

  phoneNumberFormat($event) {

    const mobile = $event.target.value;
    if (!mobile) {
      return
    }
    let phoneNumberArray
    let phoneNumber = mobile.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2 && phoneNumberArray.length != 3) { phoneNumber = phoneNumber + item + '-' }
        if (i == 2 && phoneNumberArray.length == 3) { phoneNumber = phoneNumber + item }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5 && phoneNumberArray.length != 6) { phoneNumber = phoneNumber + item + '-' }
        if (i == 5 && phoneNumberArray.length == 6) { phoneNumber = phoneNumber + item }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }

    if ([8].includes($event.keyCode) && [3, 6].includes(phoneNumberArray.length)) {
      phoneNumber = mobile.replace(/[^0-9]*/g, '')
    }

    if (phoneNumber) {
      this.profileForm.controls['phone'].setValue(phoneNumber);
    }
  }

  onlyNum(event) {
    if (isNaN(parseInt(event.key))) {
      return false;
    }
  }

  practiceFaxNumberFormat($event: any) {
    const mobile = $event.target.value;
    if (!mobile) {
      return
    }
    let phoneNumberArray
    let phoneNumber = mobile.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2 && phoneNumberArray.length != 3) { phoneNumber = phoneNumber + item + '-' }
        if (i == 2 && phoneNumberArray.length == 3) { phoneNumber = phoneNumber + item }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5 && phoneNumberArray.length != 6) { phoneNumber = phoneNumber + item + '-' }
        if (i == 5 && phoneNumberArray.length == 6) { phoneNumber = phoneNumber + item }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }

    if ([8].includes($event.keyCode) && [3, 6].includes(phoneNumberArray.length)) {
      phoneNumber = mobile.replace(/[^0-9]*/g, '')
    }

    if (phoneNumber) {
      this.profileForm.controls['fax'].setValue(phoneNumber);
    }
  }

  setContext() {
  }

  back() {
    this.navService.goto("/practice/list")
  }
  numberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5 && i < 11) { phoneNumber = phoneNumber + item }
      });
    }
    return phoneNumber;
  }

  createForm() {
    this.profileForm = this.formBuilder.group({
      name: [this.practice.name, [Validators.required, Validators.maxLength(100)]],
      email: [this.practice.email, [Validators.required, Validators.pattern(this.validator.email), Validators.maxLength(100)]],
      phone: [this.practice.phone, [Validators.required, Validators.maxLength(12), Validators.minLength(12)]],
      location: [this.practice.address.location, [Validators.required, Validators.maxLength(100)]],
      line1: [this.practice.address.line1, [Validators.maxLength(100)]],
      city: [this.practice.address.city, [Validators.required, Validators.maxLength(100)]],
      state: [this.practice.address.state, [Validators.required]],
      line2: [this.practice.address.line2],
      pinCode: [this.practice.address.pinCode, [Validators.maxLength(5), Validators.minLength(5)]],
      ein: [this.practice.meta.ein,],
      fax: [this.practice.meta.fax, [Validators.maxLength(12), Validators.minLength(12)]],
      timezoneOffset: [this.practice.timezoneOffset, [Validators.required]]
    })
  }

}
