import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UxService } from '~/app/core/services';
import { User } from '~/app/open-age/welcome/models/user.model';
import { UsersService } from '~/app/open-age/welcome/services/users.service';

@Component({
  selector: 'app-select-provider',
  templateUrl: './select-provider.component.html',
  styleUrls: ['./select-provider.component.css']
})
export class SelectProviderDialogComponent implements OnInit {

  isProcessing: boolean;
  user: User
  users: User[];
  existingUsers: User[];

  constructor(
    public dialogRef: MatDialogRef<SelectProviderDialogComponent>,
    private api: UsersService,
    private errorHandler: UxService,
  ) { }

  ngOnInit() {
    this.isProcessing = true
    this.api.search({ isAgent: true }).subscribe(page => {
      this.isProcessing = false
      this.users = []
      for (const item of page.items) {
        if (!this.checkExisting(item)) {
          this.users.push(item)
        }
      }
      if (!this.users.length) {
        if (!this.users) {
          this.errorHandler.handleError("No more providers available")
          this.dialogRef.close(false)
        }
      }
    })
  }

  select() {
    if (!this.user) {
      this.errorHandler.handleError("Please select user")
      return
    }
    this.dialogRef.close(this.user)
  }

  checkExisting(item: User): boolean {
    let exists = false
    for (const existingUser of this.existingUsers) {
      if (existingUser.id == item.id) {
        exists = true
      }
    }
    return exists
  }

}
