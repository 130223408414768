import { ModelBase } from "../../core/models";

export class Entity extends ModelBase {
  type?: string;

  constructor(obj?: any) {
        super();
        if (!obj) { }
        this.id = obj.id;
        this.code = obj.code;
        this.name = obj.name;
        this.type = obj.type;
    }
}
