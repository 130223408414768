import { PracticeLocation } from './practice-location';
import { Practitioner } from './practitioner';
import { ModelBase } from '../../core/models/model-base.model';
import { Profile } from './profile';
import { Address } from './address';
import { HealthData } from './patient-health-data';
import { Practice } from './practice';
import { CuresEntity } from './cures-entity';
import { Role } from '../../directory/models';

export class Patient extends ModelBase {

    email: string;
    phone: string;
    phoneHome: string;
    profile: Profile;
    address: Address;
    healthRecords: Array<HealthData>;
    practitioners: Practitioner[];
    providers: Practitioner[];
    practiceLocation: PracticeLocation;
    curesId: string;
    curesAll: boolean;
    curesError: string;
    curesVerified: boolean;
    ssn: string;
    mrn: string;
    practice: Practice;
    msa: Date | string;
    udt: Date | string;
    icd: {
        code: string,
        name: string
    }[]
    curesEntites: CuresEntity[]
    role: Role

    constructor(obj?: any) {
        super();

        if (!obj) {
            return;
        }

        this.id = obj.id;
        this.code = obj.code;
        this.email = obj.email;
        this.phone = obj.phone;
        this.phoneHome = obj.phoneHome;
        this.ssn = obj.ssn;
        this.mrn = obj.mrn;
        this.curesId = obj.curesId;
        this.curesError = obj.curesError;
        this.curesAll = obj.curesAll;
        this.curesVerified = obj.curesVerified;
        this.profile = new Profile(obj.profile || obj);
        this.address = new Address(obj.address);
        this.practiceLocation = new PracticeLocation(obj.practiceLocation);
        this.practice = new Practice(obj.practice);
        this.msa = obj.msa || null;
        this.udt = obj.udt || null;
        this.icd = []
        this.practitioners = []
        this.curesEntites = []
        if (obj.healthRecords && obj.healthRecords.length) {
            this.healthRecords = obj.healthRecords.map(record => new HealthData(record));
        }
        if (obj.practitioners && obj.practitioners.length) {
            this.practitioners = obj.practitioners.map(item => new Practitioner(item));
            this.providers = obj.practitioners.map(item => new Practitioner(item));
        }

        if (obj.icd && obj.icd.length) {
            for (const icd of obj.icd) {
                this.icd.push({
                    name: icd.name,
                    code: icd.code
                })
            }
        }

        if (obj.curesEntites && obj.curesEntites.length) {
            for (const curesEntity of obj.curesEntites) {
                this.curesEntites.push(new CuresEntity(curesEntity))
            }
        }

        if (obj.role && obj.role.id) {
            this.role = new Role(obj.role)
        }
    }
}
