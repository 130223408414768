import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timezone'
})
export class TimeZonePipe implements PipeTransform {

  transform(value: any, format?: string, timezone?: string): any {
    if (!timezone) {
      if (!format) {
        return moment(value).tz(moment.tz.guess()).format("MM-DD-YYYY hh:mm a");
      } else {
        return moment(value).tz(moment.tz.guess()).format(format);
      }
    } else {
      if (!format) {
        return moment(value).tz(timezone).format("MM-DD-YYYY hh:mm a");
      } else {
        return moment(value).tz(timezone).format(format);
      }
    }
  }
}
