import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { PagerBaseComponent } from '~/app/open-age/core/structures';
import { Employee } from '../models';
import { EmployeeService } from '../services';

export class EmployeeListBaseComponent extends PagerBaseComponent<Employee> implements OnInit, OnDestroy, OnChanges {

  @Input()
  readonly = false;

  @Input()
  sort = 'code';

  @Input()
  name: any;

  @Input()
  code: string;

  @Input()
  department: string;

  @Input()
  status: string;

  @Input()
  division: string;

  @Input()
  designation: string;

  @Input()
  contractor: string;

  @Input()
  userTypes: string;

  @Input()
  employeeTypes: string;

  @Input()
  organization: string;

  @Input()
  supervisor: string;

  type = 'normal'

  constructor(
    api: EmployeeService,
    errorHandler: ErrorHandler
  ) {
    super({
      api,
      errorHandler,
      filters: ['name', 'code', 'status', 'type',
        'departments', 'divisions', 'designations',
        'contractors', 'employeeTypes', 'supervisor',
        'userTypes', 'organization'],
      pageOptions: {
        limit: 5,
      },
    });
  }

  ngOnInit() {
    this.filters.set('type', this.type);
    this.fetch().subscribe(page => {
      let employees = []
      for (const item of page.items) {
        if (item.type == "normal") {
          employees.push(item)
        }
      }
      this.items = employees
    })
  }

  ngOnChanges() {
    this.filters.reset(false);
    this.filters.set('type', this.type);
    if (this.name) {
      this.filters.set('name', this.name);
    }
    if (this.organization) {
      this.filters.set('organization', this.organization);
    }
    if (this.code) {
      this.filters.set('code', this.code);
    }
    if (this.status) {
      this.filters.set('status', this.status);
    }
    if (this.userTypes) {
      this.filters.set('userTypes', this.userTypes);
    }
    if (this.employeeTypes) {
      this.filters.set('employeeTypes', this.employeeTypes);
    }
    if (this.supervisor) {
      this.filters.set('supervisor', this.supervisor);
    }
    if (this.department) {
      this.filters.set('departments', this.department);
    }
    if (this.division) {
      this.filters.set('divisions', this.division);
    }
    if (this.designation) {
      this.filters.set('designations', this.designation);
    }
    if (this.contractor) {
      this.filters.set('contractors', this.contractor);
    }
    this.fetch();
  }

  onSelect(item: Employee) {
    this.selected.emit(item);
  }

  ngOnDestroy(): void {
  }

  terminate(employee: Employee) {
    // TODO:
  }
}
