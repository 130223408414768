import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material';
import { PagerBaseComponent } from '~/app/open-age/core/structures';
import { PageOptions } from '../../core/models';
import { Organization, Role } from '../models';
import { DirectoryRoleService } from '../services';

export class AllRoleListBaseComponent extends PagerBaseComponent<Role> implements OnInit, OnDestroy, OnChanges {

  @Input()
  readonly = false;

  @Input()
  sort = 'code';

  @Input()
  name: any;

  @Input()
  type: any;

  @Input()
  roleTypeCode: any;

  @Input()
  code: string;

  @Input()
  email: string;

  @Input()
  department: string;

  @Input()
  status: string;

  @Input()
  organization: Organization;

  @Output()
  added: EventEmitter<Role> = new EventEmitter();

  columns = ['name', 'email', 'status', 'action'];

  constructor(
    private api: DirectoryRoleService,
    private errorHandler: ErrorHandler
  ) {
    super({
      api: api,
      errorHandler,
      filters: ['name', 'code', 'status',
        'user', 'type', 'roleTypeCode', 'organization'],
      pageOptions: {
        limit: 10,
      },
    });
  }

  ngOnInit() {
    this.fetch()
  }

  refresh() {
    this.filters.reset(false);
    if (this.name) {
      this.filters.set('name', this.name);
    }
    if (this.email) {
      this.filters.set('email', this.name);
    }
    if (this.type) {
      this.filters.set('type', this.type);
    }
    if (this.type != 'tenant.admin') {
      this.columns = ['name', 'email', 'status', 'action'];
    }
    if (this.roleTypeCode) {
      this.filters.set('roleTypeCode', this.roleTypeCode);
      this.columns = ['name', 'email', 'status', 'action'];
    }
    if (this.status && this.status != 'active') {
      this.filters.set('status', this.status);
    }
    if (this.organization && this.organization.code) {
      this.filters.set('organization', this.organization.code);
    }
    this.currentPageNo = 1
    this.fetch();
  }

  ngOnChanges() {
    this.refresh()
  }

  onSelect(item: Role) {
    this.selected.emit(item);
  }

  ngOnDestroy(): void {
  }

  terminate(employee: Role) {
    // TODO:
  }

  addType(item: Role) {
    this.added.emit(item);
  }

  public toggle(item: Role, event: MatSlideToggleChange) {
    this.isProcessing = true
    if (item.status == 'active') {
      item.status = 'inactive'
    } else {
      item.status = 'active'
    }
    this.api.update(item.id, item).subscribe(() => {
      this.isProcessing = false
      this.currentPageNo = 1
      this.fetch()
    })
  }

}
